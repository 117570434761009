import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { HashRouter, Routes, Route} from "react-router-dom";
import HomePage from './pages/HomePage';
import Login from './pages/Login';
import Register from './pages/Register';
import Register2 from './Components/Register2';
import Player from './pages/Player';
import Admin from './adminComponents/Admin';
import PageNotFound from './Components/PageNotFound';
import ConfirmRegistration from './Components/ConfirmRegistration';
import {AuthProvider} from './Components/AuthContext';
import LoadInitialData from './pages/LoadInitialData';
import PlayerHome from './pages/PlayerHome';
import PlayerAccount from './pages/PlayerAccount';
import NewsAlerts from './pages/NewsAlerts';
import PlayerQuest from './questCompoments/PlayerQuest';
import TestPage from './pages/TestPage';
import QuestHome from './questCompoments/QuestHome';
import LeaderBoard from './questCompoments/LeaderBoard';
import AdminHome from './adminComponents/AdminHome';
import GettingStarted from './gettingStartedComponents/GettingStarted';
import Stocks from './stockComponents/Stocks';
import MutualFunds from './stockComponents/MutualFunds';
import StockDetails from './stockComponents/StockDetails';
import StockMarket from './stockComponents/StockMarket';
import AboutStockMarket from './stockComponents/AboutStockMarket';
import Step1 from './gettingStartedComponents/Step1';
import Step2 from './gettingStartedComponents/Step2';
import Step3 from './gettingStartedComponents/Step3';
import Step4 from './gettingStartedComponents/Step4';
import AllBonds from './stockComponents/AllBonds';
import ActivateAccount from './pages/ActivateAccount';
import ResetPage1 from './resetPasswordComponents/ResetPage1';
import ResetLinkSent from './resetPasswordComponents/ResetLinkSent';
import UpdatePassword from './resetPasswordComponents/UpdatePassword';
import ResetResult from './resetPasswordComponents/ResetResult';
import ViewQuest from './questCompoments/ViewQuest';
import CreateNewQuest from './adminComponents/CreateNewQuest';
import AddStocksToQuest from './adminComponents/AddStocksToQuest';
import AddMFToQuest from './adminComponents/AddMFToQuest';
import AddBondsToQuest from './adminComponents/AddBondsToQuest';
import ManageQuest from './adminComponents/ManageQuest';
import UnpublishedQuest from './adminComponents/UnpublishedQuest';
import UpdateQuestDetails from './adminComponents/UpdateQuestDetails';
import UpdateQuestStock from './adminComponents/UpdateQuestStock';
import UpdateQuestMutualFunds from './adminComponents/UpdateQuestMutualFunds';
import UpdateQuestBonds from './adminComponents/UpdateQuestBonds';
import PublishedQuest from './adminComponents/PublishedQuest';
import ActiveQuest from './adminComponents/ActiveQuest';
import CompletedQuest from './adminComponents/CompletedQuest';
import BuyStocks from './questCompoments/BuyStocks';
import SellStocks from './questCompoments/SellStocks';
import BuyMutualFunds from './questCompoments/BuyMutualFunds';
import SellMutualFunds from './questCompoments/SellMutualFunds';
import BuyBonds from './questCompoments/BuyBonds';
import BonusQuestion from './questCompoments/BonusQuestion';
import QuestDetails from './questCompoments/QuestDetails';

import StockMarketSummary from './questCompoments/StockMarketSummary';
import NewsAlertSummary from './questCompoments/NewAlertSummary';
import BondsOwnedDetails from './questCompoments/BondsOwnedDetails';
import TransactionHistory from './questCompoments/TransactionHistory';
import BondsOwnedHistory from './questCompoments/BondsOwnedHistory';
import CreateNewAlert from './adminComponents/CreateNewAlert';
import AddMoreAlerts from './adminComponents/AddMoreAlerts';
import AllUpcomingAlerts from './adminComponents/AllUpcomingAlerts';
import UpdateNewsAlertsDetails from './adminComponents/UpdateNewsAlertsDetails';
import DeleteNewsAlert from './adminComponents/DeleteNewsAlert';
import ActiveAlerts from './adminComponents/ActiveAlerts';
import Resources from './pages/Resources';
import UpdatePlayerDetails from './pages/UpdatePlayerDetails';
import AllCompletedQuests from './pages/AllCompletedQuests';
import CreateNewAdmin from './adminComponents/CreateNewAdmin';
import ViewAllAdminUsers from './adminComponents/ViewAllAdminUsers';
import DeleteAdminUser from './adminComponents/DeleteAdminUser';
import ViewAllPlayers from './adminComponents/ViewAllPlayers';
import StockExchange from './adminComponents/StockExchange';
import DeletePlayer from './adminComponents/DeletePlayer';
import PlayerStats from './adminComponents/PlayerStats';
import HomeDetails from './pages/HomeDetails';
import ReactGA from 'react-ga4';
import AccountRegistration from "./Components/AccountRegistration/AccountRegistration";
import SchoolsRegistration from "./Components/AccountRegistration/SchoolsRegistration";
import TradingActivity from "./adminComponents/TradingActivity";
ReactGA.initialize("G-MWX2E0S2B7");




ReactDOM.render(
  <React.StrictMode>
    <HashRouter>
      <AuthProvider>
        <Routes>
          <Route path="/" element={ <HomePage />   }   exact/>
          <Route path="begin/" element={<HomeDetails/>}/>
          
          
          <Route path="login/" element= { <Login />  }  />
          <Route path="auth/:userId/:regId/" element={ <ActivateAccount /> } />
          <Route path="reset/" element= { <ResetPage1 />  }  />
          <Route path="resetLink/:value/" element= { <ResetLinkSent />  }  />
          <Route path="updatePassword/:resetId/:userId/" element={ <UpdatePassword/> } />
          <Route path="comfirmPasswordUpdate/:result/" element={ <ResetResult /> } />

          <Route path = "start/" element={ <GettingStarted />} >
            <Route index element={ <Step1 />  } />
            <Route path = "broker/" element={ <Step2  />  } />
            <Route path = "profile/" element={ <Step3  />  } />
            <Route path = "details/" element={ < Step4  />  } />
          </Route>
          <Route path = "schools/" element={ <SchoolsRegistration/>  } />
          <Route path="register/" element= { <Register />   }  >
            <Route index element={ <AccountRegistration/>  } />
            <Route path = "continue/" element={ <Register2 />  } />
            <Route path = "confirm/" element={ <ConfirmRegistration />   } />
          </Route>
          <Route path="game/" element={ <Player />  } >
            <Route index element={ <PlayerHome />  } />
            <Route path = "stockMarket/" element={ < StockMarket />   } >
              <Route index element={ <AboutStockMarket />  } />
              <Route path = "allStocks/" element={ <Stocks/>  } />
              <Route path = "allMututalFunds/" element={ <MutualFunds/>  } />
              <Route path = "allBonds/" element={ <AllBonds/>  } />
              <Route path = "details/:stockId/" element={ <StockDetails/>  }  />
            </Route>
            <Route path = "account/" element={ <PlayerAccount/>  } />
            <Route path = "updateAccount/" element={ <UpdatePlayerDetails/>  } />
            <Route path = "alerts/" element={ <NewsAlerts />   } />
            <Route path = "completedQuests/" element={ < AllCompletedQuests />   } />
            <Route path = "resources/" element={ <Resources/>  } />
            <Route path = "viewQuest/:questId" element={ <ViewQuest />   } />
            <Route path = "quest/:questId" element={ < PlayerQuest />   } >
              <Route index element={ <QuestHome />  } />
              <Route path = "marketSummary/" element={ < StockMarketSummary />  } />
              <Route path = "alertSummary/" element={ < NewsAlertSummary />  } />
              <Route path = "buyStocks/" element={ < BuyStocks />  } />
              <Route path = "sellStocks/" element={ < SellStocks />  } />
              <Route path = "buyMutualFunds/" element={ < BuyMutualFunds />  } />
              <Route path = "sellMutualFunds/" element={ < SellMutualFunds />  } />
              <Route path = "buyBonds/" element={ < BuyBonds />  } />
              <Route path = "history/:type" element={ < TransactionHistory />  } />
              <Route path = "bondHistory/" element={ < BondsOwnedHistory />  } />
              <Route path = "bonusQuestion/" element={ < BonusQuestion />  } />
              <Route path = "questDetails/" element={ < QuestDetails />  } />
              <Route path = "leaderboard/" element={ < LeaderBoard />  } />
              <Route path = "bondDetails/:BondOId" element={ < BondsOwnedDetails />  } />
            </Route>
          </Route>
          <Route path="admin/" element={<Admin /> } >
            <Route index element={ <AdminHome />  } />
            <Route path = "createQuest/" element={ < CreateNewQuest />  } />
            <Route path = "addStocks/:questId" element={ < AddStocksToQuest />  } />
            <Route path = "addMF/:questId" element={ < AddMFToQuest />  } />
            <Route path = "addBonds/:questId" element={ < AddBondsToQuest />  } />
            <Route path = "unpublishedQuest/:questId" element={ < UnpublishedQuest />  } />
            <Route path = "publishedQuest/:questId" element={ < PublishedQuest />  } />
            <Route path = "activeQuest/:questId" element={ < ActiveQuest />  } />
            <Route path = "quest-trading-activity/:questId/players/:playerName" element={ < TradingActivity />  } />
            <Route path = "completedQuest/:questId" element={ < CompletedQuest />  } />
            <Route path = "updateQuestDetails/:questId" element={ < UpdateQuestDetails />  } />
            <Route path = "updateQuestStock/:questId" element={ < UpdateQuestStock />  } />
            <Route path = "updateQuestMF/:questId" element={ < UpdateQuestMutualFunds />  } />
            <Route path = "updateQuestBonds/:questId" element={ < UpdateQuestBonds/>  } />
            <Route path = "manageQuest/" element={ < ManageQuest />  } />
            <Route path = "createNews/" element={ < CreateNewAlert />  } />
            <Route path = "moreAlertDetails/:alertId" element={ < AddMoreAlerts />  } />
            <Route path = "upcomingAlerts/" element={ < AllUpcomingAlerts/>  } />
            <Route path = "updateAlertDetails/:alertId" element={ < UpdateNewsAlertsDetails/>  } />
            <Route path = "deleteAlert/:alertId" element={ <DeleteNewsAlert />  } />
            <Route path = "activeAlerts/" element={ < ActiveAlerts />  } />
            <Route path = "createAdminUser/" element={ < CreateNewAdmin />  } />
            <Route path = "viewAdminUsers/" element={ < ViewAllAdminUsers />  } />
            <Route path = "deleteAdminUser/:adminId" element={ <DeleteAdminUser />  } />
            <Route path = "viewAllPlayers/" element={ < ViewAllPlayers />  } />
            <Route path = "stockExchange/" element={ < StockExchange />  } />
            <Route path = "deletePlayer/:playerId" element={ < DeletePlayer />  } />
            <Route path = "playerStats/" element={ < PlayerStats />  } />
          </Route>

          <Route path="*" element={ <PageNotFound />} />
        </Routes>
      </AuthProvider>
    </HashRouter>

  </React.StrictMode>,
  document.getElementById('root')
);
