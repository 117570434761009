import {Link, useParams} from "react-router-dom";
import {getApplicableAgeGroupsDescriptionForAdmin} from "../utils/quest";
import {useEffect, useState} from "react";
import {DateTime} from "luxon";
import CurrencyFormat from "react-currency-format";
import Page from "../Components/Page";

const TradingActivity = ()=>{

    const{questId, playerName} = useParams();
    const [quest, setQuest] = useState();
    const [questPlayer, setQuestPlayer] = useState();
    const [userProfile, setUserProfile] = useState();
    const [tradingHistory, setTradingHistory] = useState();

    const [dataLoaded, setDataLoaded] = useState();

    const getQuestDetails = async () =>{
        const response = await fetch(`/gameapi/trading-activity/?quest=${questId}&player=${playerName}`, {
            headers: { 'Content-Type': 'application/json' },
        });
        const data = await response.json();
        setQuest(data.questPlayer.quest);
        delete data.questPlayer.quest;
        setQuestPlayer(data.questPlayer);
        setUserProfile(data.userProfile);
        setTradingHistory(data.tradingHistory);
        setDataLoaded(true)
    }

    useEffect(() => {
        getQuestDetails();
    },[]);

    return <div>

        {dataLoaded && <div>
            <Page title={`Trading Activity for ${userProfile.playerName}`}/>
            <h3>Trading Activity for {userProfile.playerName}</h3>
            <h5>Quest: <Link to={`/admin/activeQuest/${quest.id}`}>{quest.name}</Link></h5>
            <table className="mt-5 table table-striped">
                <thead className="thead-dark">
                    <tr>
                        <th>Security</th>
                        <th>Action</th>
                        <th>Quantity</th>
                        <th>Total</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                {tradingHistory.map(trade => {
                    return <tr>
                        <td>{trade.s_mf_name}({trade.sec_type})</td>
                        <td>{trade.action}</td>
                        <td>
                            <CurrencyFormat value={parseFloat(trade.quantity)}
                                            fixedDecimalScale={0} decimalScale={0} displayType={'text'}
                                            thousandSeparator={true} prefix={''}
                            /></td>
                        <td>
                            <CurrencyFormat value={parseFloat(trade.total)}
                                            fixedDecimalScale={2} decimalScale={2} displayType={'text'}
                                            thousandSeparator={true} prefix={'$'}
                            /></td>
                        <td>{DateTime.fromISO(trade.date_bought).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}</td>
                    </tr>
                })
                }

                </tbody>
                <tfoot>
                <tr>
                    <th scope="row">{tradingHistory.length} Trades</th>
                </tr>
                </tfoot>
            </table>
        </div>
        }
    </div>
}

export default TradingActivity;